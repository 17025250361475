import QrCode from "./components/QrCode";
import "./styles.css";

export default function App() {
  return (
    <div className="section container">
      <QrCode />
    </div>
  );
}
